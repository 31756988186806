import { useRef, useState } from "react"
import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { HomeEvent } from "src/components/Homes/HomeDetails/HomeEvents/HomeEvent"
import { API_DEFAULT } from "src/constants/minutApi"
import { useFetchHomeEvents } from "src/data/events/queries/homeEventQueries"
import { IPaging } from "src/data/pagination/types/paginationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { DebugBox } from "src/ui/Debug/DebugBox"
import { GridTable } from "src/ui/GridTable/GridTable"
import PhoneSvg from "src/ui/icons/phone.svg"
import { spacing } from "src/ui/spacing"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function HomeEvents({
  homeId,
  timezone,
}: {
  homeId: string
  timezone: string
}) {
  const fetchHomeEvents = useFetchHomeEvents({ id: homeId })
  const { t, langKeys } = useTranslate()

  const [downloadUrl, setDownloadUrl] = useState("#")
  const [filename, setFilename] = useState("")
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null)

  const headers = [
    <div key={"event"}>{t(langKeys.event)}</div>,
    <></>,
    <div key={"description"}>{t(langKeys.description)}</div>,
    <div key={"time"}>{t(langKeys.time)}</div>,
  ]
  const items = (fetchHomeEvents.data?.home_events || []).map((e) => (
    <HomeEvent
      key={e.event_type + e.event_time}
      event={e}
      timezone={timezone}
    />
  ))

  async function downloadEventsCSV() {
    const response = await minutApiHttpClient.get<{
      paging: IPaging
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
      home_events: any
    }>(`${API_DEFAULT}/homes/${homeId}/events`, {
      headers: { accept: "text/csv" },
    })
    return response.data
  }

  async function exportClicked() {
    const _filename = generateFilename()
    setFilename(_filename)
    const url = generateDownloadURL(await downloadEventsCSV(), _filename)
    setDownloadUrl(url)
    downloadLinkRef.current?.click?.()
    URL.revokeObjectURL(url)
  }

  return (
    <div>
      <TableBox fetching={fetchHomeEvents.isFetching}>
        <ButtonBox>
          <MButton
            size="small"
            onClick={exportClicked}
            disabled={!items.length && false}
          >
            Download CSV
          </MButton>
          <DownloadLink
            href={downloadUrl}
            ref={downloadLinkRef}
            download={filename}
          >
            LINK
          </DownloadLink>
        </ButtonBox>
        {items.length ? (
          <GridTable
            rows={items}
            header={headers}
            templateColumns="auto 1fr 2fr auto"
            useFallbackResponsiveMode
          />
        ) : (
          <EmptyState
            icon={<PhoneSvg />}
            title="You have not received any events yet"
          />
        )}
      </TableBox>
      <DebugBox data={fetchHomeEvents.data} minimized />
    </div>
  )
}

const TableBox = styled.div<{ fetching?: boolean }>`
  width: 100%;
  overflow-x: auto;
  opacity: ${({ fetching }) => (!!fetching ? "40" : "100")}%;
`

const ButtonBox = styled.div`
  margin-bottom: ${spacing.L};
  display: flex;
  justify-content: flex-end;
`

const DownloadLink = styled.a`
  display: none;
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
function generateDownloadURL(data: any, filename: string) {
  const file = new File([data], filename, {
    type: "text/csv",
  })
  const url = URL.createObjectURL(file)
  return url
}

function generateFilename() {
  return `minutEvents-${new Date().toISOString()}.csv`
}
