import { IGuestExtended } from "src/data/guests/types/guestTypes"

const INITIAL_MODAL_STATE: IModalState = {
  addOpen: false,
  editData: undefined,
  editOpen: false,
  removeOpen: false,
  upgradeBlockerOpen: false,
}

export const INITIAL_STATE: TState = {
  guestState: [],
  modalState: INITIAL_MODAL_STATE,
}

export enum ACTION_MODAL {
  showEdit = "showEdit",
  showAdd = "showAdd",
  showUpgradeBlocker = "showUpgradeBlocker",
  closeAll = "closeAll",
  openRemove = "openRemove",
  closeRemove = "closeRemove",
}

export enum ACTION_GUEST {
  saveNew = "saveNew",
  saveEdit = "saveEdit",
  removeGuest = "removeGuest",
  setGuests = "setGuests",
}

type TModalAction = {
  type: ACTION_MODAL
  guestData?: IGuestExtended[]
}

type TGuestAction = {
  type: ACTION_GUEST
  guestData: IGuestExtended[]
}

interface IModalState {
  addOpen?: boolean
  editData?: undefined | IGuestExtended
  editOpen?: boolean
  removeOpen?: boolean
  upgradeBlockerOpen?: boolean
}

export type TState = {
  guestState: IGuestExtended[]
  modalState: IModalState
}

export type TAction = TModalAction | TGuestAction

export function reducer(
  state: TState,
  action: TAction | { type: "err"; guestData: undefined }
): TState {
  const incomingGuestData: IGuestExtended[] = action.guestData || []
  let guests: IGuestExtended[]

  switch (action.type) {
    // Guest actions
    case ACTION_GUEST.saveNew:
      return {
        ...INITIAL_STATE,
        guestState: [...state.guestState, ...incomingGuestData],
      }
    case ACTION_GUEST.saveEdit:
      guests = state.guestState.map((guest) =>
        guest.guest_id === incomingGuestData[0].guest_id
          ? incomingGuestData[0]
          : guest
      )
      return {
        ...INITIAL_STATE,
        guestState: guests,
      }
    case ACTION_GUEST.removeGuest:
      guests = state.guestState.filter(
        (guest) => guest.guest_id !== incomingGuestData[0].guest_id
      )
      return {
        ...INITIAL_STATE,
        guestState: guests,
      }
    case ACTION_GUEST.setGuests:
      return {
        ...INITIAL_STATE,
        guestState: incomingGuestData,
      }

    // Modal actions
    case ACTION_MODAL.showAdd:
      return {
        ...state,
        modalState: {
          ...INITIAL_MODAL_STATE,
          addOpen: true,
        },
      }
    case ACTION_MODAL.showEdit:
      return {
        ...state,
        modalState: {
          ...INITIAL_MODAL_STATE,
          editOpen: true,
          editData: action.guestData?.[0],
        },
      }
    case ACTION_MODAL.openRemove:
      return {
        ...state,
        modalState: {
          ...state.modalState,
          removeOpen: true,
          editData: action.guestData?.[0],
        },
      }
    case ACTION_MODAL.closeRemove:
      return {
        ...state,
        modalState: {
          ...state.modalState,
          removeOpen: false,
        },
      }
    case ACTION_MODAL.showUpgradeBlocker:
      return {
        ...state,
        modalState: {
          ...INITIAL_MODAL_STATE,
          upgradeBlockerOpen: true,
        },
      }
    case ACTION_MODAL.closeAll:
      return {
        ...state,
        modalState: INITIAL_MODAL_STATE,
      }

    default:
      // eslint-disable-next-line no-console
      console.error(`invalid guest action type '${action.type}'`)
      return state
  }
}
