import { useEffect, useReducer, useState } from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { CircularProgress } from "@material-ui/core"
import { startOfDay, subDays } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { extendGuestsData } from "src/components/Homes/Guests/guestsUtil"
import { Pager } from "src/components/Pager/Pager"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import {
  SortKey,
  SortOrder,
  useFetchGuests,
} from "src/data/guests/queries/guestQueries"
import {
  IGuestExtended,
  IGuestResponse,
} from "src/data/guests/types/guestTypes"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import GuestModals from "./GuestModals"
import {
  ACTION_GUEST,
  ACTION_MODAL,
  INITIAL_STATE,
  reducer,
} from "./guestsState"
import { GuestTable } from "./GuestTable"

export function Guests({
  homeId,
  canEdit,
  homeTz,
}: {
  homeId: string
  homeTz: string
  canEdit?: boolean
}) {
  const { t } = useTranslate()
  const { org } = useOrganization()
  const guestConnectAvailability = useFeatureAvailability({
    feature: "guest_connect",
  })

  const [offset, setOffset] = useState(0)
  const limit = 10
  const fetchGuests = useFetchGuests(homeId, {
    limit,
    offset,
    checkout_filter: {
      from: subDays(zonedTimeToUtc(startOfDay(new Date()), "Etc/GMT"), 14),
    },
    sort_options: {
      key: SortKey.CHECK_IN,
      order: SortOrder.ASCENDING,
    },
  })

  const fetchIntegrations = useFetchIntegrations({
    orgId: org.id,
    filters: { includeExternalIntegrations: true },
  })

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const [extendedGuestsData, setExtendedGuestsData] = useState<
    IGuestExtended[]
  >([])

  useEffect(() => {
    if (fetchGuests.isLoading || !fetchGuests.data) {
      return
    }

    const guests: IGuestResponse[] = fetchGuests.data.guests
    const integrations = fetchIntegrations.data?.integrations ?? []
    const extendedGuestsData = extendGuestsData(integrations, guests)
    setExtendedGuestsData(extendedGuestsData)

    dispatch({ type: ACTION_GUEST.setGuests, guestData: extendedGuestsData })
  }, [
    fetchGuests.data,
    fetchGuests.isLoading,
    fetchIntegrations.data?.integrations,
  ])

  useEffect(() => {
    if (offset !== 0 && fetchGuests.data?.guests.length === 0) {
      //Handles the case where the last user on a page is deleted;
      //then we want to fetch the previous page of users
      setOffset((oldOffset) => Math.max(0, oldOffset - limit))
    }
  }, [fetchGuests.data?.guests.length, offset])

  function addClicked() {
    guestConnectAvailability.available
      ? dispatch({ type: ACTION_MODAL.showAdd })
      : dispatch({ type: ACTION_MODAL.showUpgradeBlocker })
  }

  return (
    <>
      <TopBox>
        <header>
          <FeatureBlockerText
            hidden={!guestConnectAvailability.ready}
            isFeatureAvailable={guestConnectAvailability.available}
          >
            <MText variant="heading2">{t(langKeys.guest, { count: 2 })}</MText>
          </FeatureBlockerText>

          <MText variant="body" color="secondary">
            <ReactMarkdown>
              {t(langKeys.guests_group_settings_description)}
            </ReactMarkdown>
          </MText>
        </header>

        <div>
          <AddButton
            onClick={addClicked}
            size="small"
            disabled={!canEdit || !guestConnectAvailability.ready}
          >
            {t(langKeys.add_guest)}
          </AddButton>
        </div>
      </TopBox>

      {guestConnectAvailability.available && (
        <>
          {(fetchGuests.data?.guests && (
            <GuestTable
              onEdit={(data: IGuestExtended) =>
                dispatch({ type: ACTION_MODAL.showEdit, guestData: [data] })
              }
              onRemove={(data: IGuestExtended) => {
                dispatch({ type: ACTION_MODAL.openRemove, guestData: [data] })
              }}
              guests={extendedGuestsData}
              canEdit={!!canEdit}
              timezone={homeTz}
            />
          )) || <TableLoadingIndicator />}
        </>
      )}
      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchGuests.data?.paging.total_count}
        />
      </PagerWrapper>

      <GuestModals
        homeId={homeId}
        timezone={homeTz}
        dispatch={dispatch}
        state={state}
      />
    </>
  )
}

const TopBox = styled.div`
  margin-bottom: ${spacing.M};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  header {
    flex: 0 1 60ch;
  }
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const TableLoadingIndicator = () => (
  <div style={{ display: "flex" }}>
    <CircularProgress size="30px" />
  </div>
)
