import styled from "styled-components"

import { THomeEvent } from "src/data/events/types/homeEventTypes"
import FallbackEventSvg from "src/ui/icons/eventIcons/event-home.svg?inline"
import { LocalizedDate } from "src/ui/LocalizedDate/LocalizedDate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeEvent({
  event,
  timezone,
}: {
  event: THomeEvent
  timezone: string | undefined
}) {
  const { title, body, icon, subtitle } = event.user_representation
  const _icon = icon ?? FallbackEventSvg

  return (
    <>
      <IconBox>{<img src={_icon} alt={title} />}</IconBox>
      <div>
        <MText variant="subtitle">{title}</MText>
        <div>{subtitle}</div>
      </div>
      <div>{body}</div>
      <LocalizedDate date={event.event_time} timezone={timezone} />
    </>
  )
}

const IconBox = styled.div`
  img {
    width: 40px;
  }
  padding-right: ${spacing.S};
`
