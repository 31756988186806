import { useState } from "react"
import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import {
  CardStatusRow,
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { SmokingDetectionPopover } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionPopover"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { IHome, ISmokingDetection } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import CalibratingIcon from "src/ui/icons/calibrating.svg"
import SmokingDetectionIcon from "src/ui/icons/cigarette-detection.svg"
import AlertingIcon from "src/ui/icons/important-outlined.svg"
import { spacing } from "src/ui/spacing"
import { formatDateAsTime } from "src/utils/l10n"

export function SmokingDetectionCard({
  smokingDetection,
  violations,
  homeId,
  toggleAllowed,
  devices,
}: {
  smokingDetection: ISmokingDetection
  violations: IHome["violations"]
  homeId: IHome["home_id"]
  toggleAllowed: boolean
  devices: TDevice[]
}) {
  const { t, langKeys } = useTranslate()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)

  const isEnabled = smokingDetection.state === "enabled"
  const isAnalyzing =
    isEnabled &&
    !!smokingDetection.calibration_done_at &&
    new Date() < new Date(smokingDetection.calibration_done_at)
  const statusContents = isEnabled ? t(langKeys.on) : t(langKeys.off)

  const featureAvailabilityCigaretteSmoke = useFeatureAvailability({
    feature: Feature.CIGARETTE_SMOKE,
  })
  const CigaretteFeatureAvailableInPlan =
    featureAvailabilityCigaretteSmoke.available &&
    featureAvailabilityCigaretteSmoke.ready

  function handleClickToggle(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    CigaretteFeatureAvailableInPlan ? setOpen(true) : setShowUpgradeDialog(true)

    setAnchorEl(event.currentTarget)
  }

  const tooltipText = toggleAllowed ? "" : t(langKeys.not_enough_permissions)

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }
  return (
    <div>
      <MonitoringCard
        titleSlots={[
          <StatusLeft
            key="smoke-status-left"
            icon={<SmokingDetectionIcon width={32} height={32} />}
            title={t(langKeys.smoking_detection_title)}
            statusContents={statusContents}
            isOn={isEnabled}
            showUpgradeButton={
              !featureAvailabilityCigaretteSmoke.available ||
              !featureAvailabilityCigaretteSmoke.ready
            }
          />,
          <Tooltip key="smoke-tooltip" title={tooltipText} placement={"top"}>
            <div>
              <MButton
                variant="subtle"
                size="small"
                key="right"
                onClick={handleClickToggle}
                disabled={!toggleAllowed}
                loading={!featureAvailabilityCigaretteSmoke.ready}
              >
                {t(langKeys.manage)}
              </MButton>
            </div>
          </Tooltip>,
        ]}
        cardFooter={{
          showTopBorder: isAnalyzing || smokingDetection.status === "alerting",
          component: (
            <CigaretteDetectionFooter
              isAnalyzing={isAnalyzing}
              status={smokingDetection.status}
              devices={devices}
            />
          ),
        }}
      ></MonitoringCard>

      {open && (
        <SmokingDetectionPopover
          homeId={homeId}
          anchorEl={anchorEl}
          violations={violations}
          state={smokingDetection.state}
          onClose={handleClose}
        />
      )}

      <UpgradeBlockerDialog
        open={showUpgradeDialog}
        onClose={() => setShowUpgradeDialog(false)}
      />
    </div>
  )
}

function CigaretteDetectionFooter({
  isAnalyzing,
  status,
  devices,
}: {
  isAnalyzing: boolean
  status: ISmokingDetection["status"]
  devices: TDevice[]
}) {
  const { t, langKeys } = useTranslate()

  const clockType = useGetUser().clock_type

  if (isAnalyzing) {
    return (
      <CardStatusRow
        icon={<CalibratingIcon width={24} />}
        subtitle={t(langKeys.smoking_detection_calibrating_title)}
        body={t(langKeys.smoking_detection_calibrating_body)}
      />
    )
  }

  if (status === "alerting") {
    const alertingDevice = devices.find(
      (device) => device.smoking_detection?.status === "alerting"
    )

    const alertTime = formatDateAsTime({
      date: alertingDevice?.smoking_detection?.updated_at || "",
      clockType,
      excludeSeconds: true,
    })
    const deviceName = alertingDevice?.description

    return (
      <CardStatusRow
        icon={<AlertingIcon width={24} />}
        subtitle={t(langKeys.smoking_detection_cigarette_smoke_detected)}
        body={
          <StatusBox>
            <div>{alertTime}</div>
            <div>&#x2022;</div>
            <div>{deviceName}</div>
          </StatusBox>
        }
        color="emergency"
      />
    )
  }

  return null
}

const StatusBox = styled.div`
  display: flex;
  gap: ${spacing.XS2};
`
