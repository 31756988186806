import { useRef, useState } from "react"
import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import { IndoorClimateStatus } from "src/components/Devices/IndoorClimateStatus"
import { IndoorClimatePopover } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimatePopover"
import {
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { usePostIndoorClimatePopupOpened } from "src/data/analytics/queries/homeOverviewCardsAnalyticsQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { IHome, TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { colorsV2 } from "src/ui/colors"
import HumidityOnIcon from "src/ui/icons/humidity-on-filled.svg"
import ImportantIcon from "src/ui/icons/important-outlined.svg"
import IndoorClimateIcon from "src/ui/icons/indoor-climate.svg"
import TemperatureOnIcon from "src/ui/icons/temperature-on-filled.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IndoorClimateCard({
  devices,
  home,
  indoorClimateMonitoring,
  toggleAllowed,
}: {
  devices: TDevice[]
  home: IHome
  indoorClimateMonitoring?: TIndoorClimateMonitoring
  toggleAllowed: boolean
}) {
  const { t, langKeys } = useTranslate()

  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  const isEnabled = indoorClimateMonitoring?.state === "enabled"
  const statusContents = isEnabled ? t(langKeys.on) : t(langKeys.off)

  const tooltipText = toggleAllowed ? "" : t(langKeys.not_enough_permissions)

  const hideMonitoringValues =
    !indoorClimateMonitoring?.humidity_enabled &&
    !indoorClimateMonitoring?.temperature_enabled

  const postIndoorClimatePopupOpened = usePostIndoorClimatePopupOpened()

  function handleOpenDialog() {
    setOpen(true)
    trackIndoorClimatePopupOpened()
  }

  function trackIndoorClimatePopupOpened() {
    const trackedData = {
      context: "home details",
      icm_enabled: indoorClimateMonitoring?.state === "enabled" ? true : false,
      temperature_enabled: !!indoorClimateMonitoring?.temperature_enabled,
      humidity_enabled: !!indoorClimateMonitoring?.humidity_enabled,
    }

    postIndoorClimatePopupOpened.mutate(trackedData)
  }
  return (
    <MonitoringCard
      titleSlots={[
        <StatusLeft
          key="left"
          title={t(langKeys.indoor_climate)}
          icon={<IndoorClimateIcon width={32} height={32} />}
          statusContents={statusContents}
          isOn={isEnabled}
          secondaryFeatureStatus={
            !!indoorClimateMonitoring && (
              <SecondaryFeatureStatus
                indoorClimateMonitoring={indoorClimateMonitoring}
              />
            )
          }
        />,
        <Tooltip
          key="indoor-climate-tooltip"
          title={tooltipText}
          placement={"top"}
        >
          <div>
            <MButton
              key="right"
              variant="subtle"
              size="small"
              onClick={handleOpenDialog}
              disabled={!toggleAllowed}
              ref={ref}
            >
              {t(langKeys.manage)}
            </MButton>
          </div>
        </Tooltip>,
      ]}
      cardFooter={{
        showTopBorder: isEnabled && hideMonitoringValues,
        component: isEnabled && hideMonitoringValues && (
          <AlertBox>
            <ImportantIcon width={24} color={colorsV2.systemErrorDark} />

            <MText color="emergency">
              {t(langKeys.indoor_climate_all_notifications_off_error)}
            </MText>
          </AlertBox>
        ),
      }}
    >
      <IndoorClimateDevices
        indoorClimateMonitoring={indoorClimateMonitoring}
        hidden={hideMonitoringValues}
        devices={devices}
      />

      {open && home.indoor_climate_monitoring && (
        <IndoorClimatePopover
          homeId={home.home_id}
          indoorClimateMonitoring={home.indoor_climate_monitoring}
          anchorEl={ref.current}
          onClose={() => setOpen(false)}
        />
      )}
    </MonitoringCard>
  )
}

function IndoorClimateDevices({
  indoorClimateMonitoring,
  devices,
  hidden,
}: {
  indoorClimateMonitoring?: TIndoorClimateMonitoring
  devices: TDevice[]
  hidden: boolean
}) {
  if (indoorClimateMonitoring?.state !== "enabled") {
    return null
  }

  if (hidden) {
    return null
  }

  return (
    <Box>
      {devices.map((device) => (
        <DetailsBox key={device.device_id}>
          <StyledMText variant="body">{device.description}</StyledMText>

          <IndoorClimateStatus
            device={device}
            indoorClimateMonitoring={indoorClimateMonitoring}
          />
        </DetailsBox>
      ))}
    </Box>
  )
}

function SecondaryFeatureStatus({
  indoorClimateMonitoring,
}: {
  indoorClimateMonitoring: TIndoorClimateMonitoring
}) {
  const iconFilter = [
    {
      icon: <TemperatureOnIcon key="temperature-icon" width={24} height={24} />,
      hidden: !indoorClimateMonitoring.temperature_enabled,
    },
    {
      icon: <HumidityOnIcon key="humidity-icon" width={24} height={24} />,
      hidden: !indoorClimateMonitoring.humidity_enabled,
    },
  ].filter((i) => !i.hidden)

  return <>{iconFilter.map((i) => i.icon)}</>
}

const DetailsBox = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, auto) 1fr;
  max-width: max-content;
`

const Box = styled.div`
  margin-top: ${spacing.L};
  padding-top: ${spacing.L};
  border-top: 1px solid ${colorsV2.divider};
`

const StyledMText = styled(MText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12ch;
`

const AlertBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
`
