import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { THomeEvent } from "src/data/events/types/homeEventTypes"
import { homeKeys } from "src/data/homes/queries/homeQueryCache"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { IPaging } from "src/data/pagination/types/paginationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"
interface IPagedHomeEvent {
  home_events: THomeEvent[]
  paging: IPaging
}

export function useFetchHomeEvents({
  id,
  options,
}: {
  id: string
  options?: UseQueryOptions<
    IPagedHomeEvent,
    AxiosError,
    IPagedHomeEvent,
    ReturnType<typeof homeKeys.events>
  >
}) {
  const { orgId } = useOrganization()
  async function fetchHomeEvents(id: string) {
    const response = await minutApiHttpClient.get<IPagedHomeEvent>(
      `${API_DEFAULT}/homes/${id}/events`
    )
    const home = response.data
    return home
  }

  return useQuery(homeKeys.events(orgId, id), () => fetchHomeEvents(id), {
    staleTime: 60000,
    keepPreviousData: true,
    ...options,
  })
}
