import { useMemo } from "react"
import styled from "styled-components"

import { subHours } from "date-fns"

import { useFetchDeviceReadings } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { NoiseMonitoringState } from "src/data/homeActions/types/noiseMonitoringTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { semanticEmergency, semanticGood } from "src/ui/colors"
import { extractActiveNoiseThreshold } from "src/ui/Graphs/configurationUtils"
import { LineGraphResponsive } from "src/ui/Graphs/LineGraph/LineGraph"
import { getResolution } from "src/ui/Graphs/utils"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { utcToHomeTimezone } from "src/utils/l10n"

import { GraphBox } from "./GraphBox"

export function NoiseMonitoringGraph({
  title,
  device,
  noiseMonitoringState,
  timezone,
}: {
  device: TDevice
  noiseMonitoringState?: NoiseMonitoringState
  title?: string
  timezone?: string
}) {
  const { orgId } = useOrganization()
  const endAt = useMemo(
    () => new Date(device.last_heartbeat_at ?? Date.now()),
    [device.last_heartbeat_at]
  )
  const startAt = subHours(endAt, 1)

  const fetchDeviceReadings = useFetchDeviceReadings({
    orgId,
    type: "sound_level",
    deviceId: device.device_id,
    startAt: startAt.toISOString(),
    endAt: endAt.toISOString(),
    timeResolution: getResolution({ startDate: startAt, endDate: endAt }),
  })

  const expectedValues =
    fetchDeviceReadings.data?.values.map((reading) => ({
      ...reading,
      datetime: utcToHomeTimezone(reading.datetime, timezone),
    })) || []

  function getLineColor() {
    return noiseMonitoringState === "idle" ? semanticGood : semanticEmergency
  }

  const activeNoiseThreshold = extractActiveNoiseThreshold(
    device.configuration,
    true
  )

  const timeDomain = [
    utcToHomeTimezone(startAt.toISOString(), timezone),
    utcToHomeTimezone(endAt.toISOString(), timezone),
  ]

  return (
    <Box>
      {!!title && <MText variant="heading3">{title}</MText>}
      <GraphBox isLoading={fetchDeviceReadings.isLoading}>
        <LineGraphResponsive
          margin={{ left: 30, right: 30, bottom: 30, top: 30 }}
          data={[expectedValues]}
          unitSymbol="dB"
          yMaxOffset={0}
          yMinOffset={5}
          line={{ stroke: getLineColor() }}
          showLineEndIndicator
          axisBottom={{
            showFirstAndLast: true,
          }}
          disableTooltip
          stepThresholds={activeNoiseThreshold}
          timeDomain={timeDomain}
        />
      </GraphBox>
    </Box>
  )
}

const Box = styled.div`
  margin-top: ${spacing.M};
`
