import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { extendInternallyCreatedGuest } from "src/components/Homes/Guests/guestsUtil"
import {
  useDeleteGuest,
  usePostGuest,
  usePutGuest,
} from "src/data/guests/queries/guestQueries"
import {
  IGuestExtended,
  IGuestPost,
  IGuestPut,
} from "src/data/guests/types/guestTypes"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"

import { GuestSMSConfigDialog } from "./GuestSmsConfig"
import { ACTION_GUEST, ACTION_MODAL, TAction, TState } from "./guestsState"

// eslint-disable-next-line import/no-default-export
export default function GuestModals({
  homeId,
  timezone,
  dispatch,
  state,
}: {
  homeId: string
  timezone?: string
  dispatch: React.Dispatch<TAction>
  state: TState
}) {
  const deleteGuest = useDeleteGuest({ homeId })
  const addGuest = usePostGuest()
  const editNewGuest = usePutGuest()
  const guest: IGuestExtended | undefined = state.modalState.editData

  function removeGuest(guest?: IGuestExtended) {
    if (!guest) return

    deleteGuest.mutate(guest.guest_id, {
      onSuccess: () => {
        dispatch({ type: ACTION_GUEST.removeGuest, guestData: [guest] })
      },
    })
  }

  function saveNewGuest(guest: IGuestPost) {
    addGuest.mutate(
      { guest, homeId },
      {
        onSuccess: (newGuest) => {
          const guestData: IGuestExtended[] = [
            extendInternallyCreatedGuest(newGuest),
          ]
          dispatch({ type: ACTION_GUEST.saveNew, guestData })
          dispatch({ type: ACTION_MODAL.closeAll })
        },
      }
    )
  }

  function editGuest(data: IGuestPut) {
    if (!guest?.guest_id) return

    data.home_id = homeId

    editNewGuest.mutate(
      { guest: data },
      {
        onSuccess: (newGuest) => {
          const guestData: IGuestExtended[] = [
            extendInternallyCreatedGuest(newGuest),
          ]
          dispatch({ type: ACTION_GUEST.saveEdit, guestData })
          dispatch({ type: ACTION_MODAL.closeAll })
        },
      }
    )
  }

  const showRemoveConfirm = () => {
    dispatch({
      type: ACTION_MODAL.openRemove,
      guestData: guest ? [guest] : undefined,
    })
  }
  const closeRemoveConfirm = () => dispatch({ type: ACTION_MODAL.closeRemove })
  const closeAllModals = () => dispatch({ type: ACTION_MODAL.closeAll })

  return (
    <>
      <GuestSMSConfigDialog
        open={!!state.modalState.addOpen}
        homeId={homeId}
        timezone={timezone}
        onSave={saveNewGuest}
        onCancel={closeAllModals}
        loading={addGuest.isLoading}
      />

      <UpgradeBlockerDialog
        open={!!state.modalState.upgradeBlockerOpen}
        onClose={closeAllModals}
      />

      <GuestSMSConfigDialog
        key={guest?.guest_id}
        open={!!state.modalState.editOpen}
        homeId={homeId}
        timezone={timezone}
        onEdit={editGuest}
        onCancel={closeAllModals}
        onRemove={showRemoveConfirm}
        loading={editNewGuest.isLoading}
        guest={guest}
        edit
      />

      <ConfirmDialog
        open={!!state.modalState.removeOpen}
        onClose={closeRemoveConfirm}
        title={`Remove '${guest?.name}'`}
        description={"This guest will be permanently removed."}
        confirmButtonProps={{ color: "destructive" }}
        confirmLabel="Remove"
        onConfirm={() => removeGuest(guest)}
        loading={deleteGuest.isLoading}
      />
    </>
  )
}
